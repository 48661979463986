

















import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {},
})
export default class VCustomSelect extends Vue {
  @Prop({
    default: () => {
      return []
    },
  })
  items
  @Prop({ default: '' }) label
  @Prop({ default: '' }) itemValue
  @Prop({ default: '' }) itemText
  @Prop({
    default: () => {
      return {}
    },
  })
  value
  @Prop({ default: false }) multiple
}
